
import {Options, Vue} from "vue-class-component";
import axios from "axios";

@Options({
  components: {},
})
export default class AppComponent extends Vue {
  public TitlePage: string = ""
}
