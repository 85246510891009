
import {Options, Vue} from "vue-class-component";
import axios from "axios";

@Options({
  components: {},
})
export default class HomeView extends Vue {
  private HasLoading: boolean = true;
  private DashboardData?: {
    "title": string,
    "questions": number,
    "last_step": number,
    "has_final": boolean
  } = undefined;

  async created() {
    try {
      this.DashboardData = (await axios.get("/api/test/" + this.$route.params.id)).data;
      if (this.DashboardData?.has_final == true) {
        this.OnClickStart();
        return;
      }
      this.HasLoading = false;
      this.$forceUpdate();
    } catch (e) {
      this.$router.push("/");
    }
  }

  private OnClickStart() {
    this.$router.push("/play/" + this.$route.params.id);
  }
}
