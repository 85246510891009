import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TestingView from "@/views/TestingView.vue";
import BeforeView from "@/views/BeforeView.vue";
import AdminView from "@/views/Admin/AdminView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'before',
    component: BeforeView
  },
  {
    path: '/system/cp',
    name: 'systemcp',
    component: AdminView
  },
  {
    path: '/start/:id',
    name: 'start',
    component: HomeView
  },
  {
    path: '/play/:id',
    name: 'play',
    component: TestingView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
