
import {Options, Vue} from "vue-class-component";
import axios from "axios";

interface IResponseItem {
  "template": string,
  "name": null | string,
  "contact": null | string,
  "price": null | string,
  "start_at": number,
  "last_step": number,
  "last_step_start_at": number,
  "questions": {
    "question": string,
    "answer": string,
    "good_answer": string,
    time_for_answer: number,
    "is_good_answer": boolean
  }[],
  "title": string,
  "file": string,
  all_question: {
    "question": string,
    "options": string[],
    "answer": number
  }[]
}

@Options({
  components: {},
})
export default class AdminView extends Vue {
  private HasLoading: boolean = true;
  private Response!: IResponseItem[];
  private AllTemplates!: {
    "file": string,
    "title": string
  }[];
  private copyRow: string = "";
  private SelectedInvite: IResponseItem | undefined = undefined;

  async created() {
    document.body.style.overflowY = "auto";
    await this.Reload();
  }

  private async Reload() {
    this.HasLoading = true;
    if (this.$.isMounted) {
      this.$forceUpdate();
    }
    this.Response = (await axios.get("/api/admin/invites")).data;
    this.AllTemplates = (await axios.get("/api/admin/tests")).data;
    this.HasLoading = false;
    if (this.$.isMounted) {
      this.$forceUpdate();
    }
  }

  CopyLink(item: IResponseItem) {
    this.copyRow = item.file;
    this.$forceUpdate();
    navigator.clipboard.writeText("https://quest.ecosys.appdvlprs.com/start/" + item.file);
  }

  UnhoverCopy() {
    this.copyRow = "";
    this.$forceUpdate();
  }

  GetClassForToolbar(item: IResponseItem) {
    return ((this.copyRow == item.file) ? "tooltip-show" : "");
  }

  GetStartDate(item: IResponseItem) {
    if (item.start_at == 0) {
      return "-";
    }
    const date = new Date(item.start_at);
    return this.DateNumberToString(date.getDate()) + "." + this.DateNumberToString(date.getMonth() + 1) + "." + date.getFullYear() + " " + this.DateNumberToString(date.getHours()) + ":" + this.DateNumberToString(date.getMinutes());
  }

  DateNumberToString(num: any) {
    num = String(num);
    return (num.length == 1 ? "0" : "") + num;
  }

  GetTestContact(item: IResponseItem) {
    return (item.name == undefined || item.name.length == 0) ? " - " : item.name + "<br>" + item.contact + "<br>ЗП:" + item.price;
  }

  GetResult(item: IResponseItem) {
    return "Ответов: " + item.questions.length + " / " + item.all_question.length + "; Правильных: " + item.questions.filter(it => it.is_good_answer == true).length;
  }

  OpenDetails(item: IResponseItem) {
    this.SelectedInvite = item;
    this.$forceUpdate();
  }

  CloseDetails() {
    this.SelectedInvite = undefined;
    this.$forceUpdate();
  }

  private async CreateNew() {
    try {
      this.HasLoading = true;
      this.$forceUpdate();
      let text = "Введите цифру варианта теста:";
      for (let i = 0; i < this.AllTemplates.length; i++) {
        text += "\n" + i + ": " + this.AllTemplates[i].title;
      }
      const result = Number(prompt(text, ""));
      if (isNaN(result) || result >= this.AllTemplates.length || result < 0) {
        alert("Вы выбрали не верный вариант");
      } else {
        const invite_code: string = (await axios.post("/api/admin/invite", {
          template: this.AllTemplates[result].file
        })).data.invite_code;
        prompt("Ссылка на прохождение тестирования: ", "https://quest.ecosys.appdvlprs.com/start/" + invite_code);
      }
    } catch (e) {
      //
    }
    await this.Reload();
  }

}
