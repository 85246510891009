
import {Options, Vue} from "vue-class-component";
import axios from "axios";

@Options({
  components: {},
})
export default class TestingView extends Vue {
  private HasLoading: boolean = true;
  private QuestionData?: {
    "question": string,
    "options": string[],
    "step": number,
    "start_at": number
  } = undefined;
  private DashboardData?: {
    "title": string,
    "questions": number,
    "last_step": number,
    "has_final": boolean
  } = undefined;
  private LeftSecond: number = 30;
  private SelectedAnswer: number = -1;
  private ContactName?: string;
  private Contact?: string;
  private Price?: string;
  private HasFinal: boolean = false;

  async created() {
    await this.LoadNextQuestion();
    setInterval(() => {
      this.CalculateSeconds();
    }, 1000);
  }

  private CalculateSeconds() {
    if (this.HasLoading == false) {
      this.LeftSecond = 30 - Math.round((Date.now() - (this.QuestionData?.start_at || 0)) / 1000);
      if (this.LeftSecond < 0) {
        if (this.DashboardData?.questions != this.DashboardData?.last_step) {
          this.SendAnswer(-1);
        }
        this.LeftSecond = 0;
      }
      this.$forceUpdate();
    }
  }

  private async LoadNextQuestion() {
    try {
      this.SelectedAnswer = -1;
      this.DashboardData = (await axios.get("/api/test/" + this.$route.params.id)).data;
      if (this.DashboardData?.has_final == true) {
        this.HasFinal = true;
      }
      try {
        this.QuestionData = (await axios.get("/api/test/" + this.$route.params.id + "/question")).data;
      } catch (e) {
        this.QuestionData = undefined;
      }
      this.HasLoading = false;
      this.$forceUpdate();
      this.CalculateSeconds();

      const parentElement = this.$parent as any;
      parentElement.TitlePage = this.DashboardData?.title || "";
      parentElement.$forceUpdate();
    } catch (e) {
      this.$router.push("/start/" + this.$route.params.id);
    }
  }

  private async SendAnswer(id: number) {
    this.HasLoading = true;
    this.$forceUpdate();

    (await axios.post("/api/test/" + this.$route.params.id + "/answer", {
      answer: this.SelectedAnswer
    }));
    await this.LoadNextQuestion();
  }

  private async SendFinal() {
    if ((this.ContactName?.length || 0) == 0) {
      alert("Вы не заполнили имя");
      return;
    }
    if ((this.Contact?.length || 0) == 0) {
      alert("Вы не заполнили контактные данные");
      return;
    }
    if ((this.Price?.length || 0) == 0) {
      alert("Вы не заполнили ожидание по зарплате");
      return;
    }
    this.HasLoading = true;
    this.$forceUpdate();
    (await axios.post("/api/test/" + this.$route.params.id + "/final", {
      name: this.ContactName,
      contact: this.Contact,
      price: this.Price
    }));
    this.LoadNextQuestion();
  }
}
